import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Pizza Web Dev Prototype
        <img src="pizza-icon.png" alt="" />
      </header>
    </div>
  );
}

export default App;
